import React, { useRef, useMemo, useCallback, useEffect } from 'react'
import { useParams, useHistory, useLocation, matchPath } from 'react-router-dom'
import styled from 'styled-components'

// Routes
import { STORE_PATH } from 'routes/store/path'

// Hooks
import { useGetBoardList, useGetTopNoticeList } from 'hooks/board/useBoardAsync'
import useScrollPosition from 'hooks/store/useScrollPosition'

// Components
import BoardList from 'components/Board/Common/Core/BoardList'
import SearchForm from 'components/Board/Common/Core/SearchForm'
import OverlayScroll from 'components/Common/OverlayScroll'
import TopButton from 'components/Popup/components/common/TopButton'

import { media } from 'assets/styles/media'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
  ${media.phone`
    margin-bottom: 20px;
  `};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 12px;
`
const ListHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-bottom: 32px;

  ${media.phone`
    margin-bottom: 16px;
  `};
`

const BoardListWrapper = ({ children, hasScroll }) => {
  const history = useHistory()
  const location = useLocation()
  const scrollRef = useRef(null)
  const { checkScrollPosition, showTopButton } = useScrollPosition({
    scrollRef
  })

  // 이펙트 - 저장된 스크롤 위치로 이동
  useEffect(() => {
    if (hasScroll) {
      const match = matchPath(location.pathname, {
        path: STORE_PATH.GAME,
        exact: true,
        strict: true
      })

      if (match && !match.params.articleId) {
        if (sessionStorage.getItem('scrollPosition')) {
          const savedScrollPosition =
            parseInt(sessionStorage.getItem('scrollPosition'), 10) || 0

          if (scrollRef.current) {
            scrollRef?.current
              ?.osInstance()
              ?.elements()
              ?.viewport.scroll({ top: savedScrollPosition })
            sessionStorage.removeItem('scrollPosition')
          }
        }
      }
    }
  }, [location, scrollRef, hasScroll])

  // 이펙트 - 스크롤 위치 저장
  useEffect(() => {
    const unlisten = history.listen(() => {
      const match = matchPath(history.location.pathname, {
        path: STORE_PATH.GAME,
        exact: true,
        strict: true
      })

      if (scrollRef.current !== null) {
        if (match && match.params.articleId) {
          sessionStorage.setItem(
            'scrollPosition',
            scrollRef?.current?.osInstance()?.elements()?.viewport?.scrollTop
          )
        }
      }
    })

    return () => {
      if (hasScroll) {
        unlisten()
      }
    }
  }, [history, scrollRef, hasScroll])

  return hasScroll ? (
    <Wrapper>
      <OverlayScroll
        ref={scrollRef}
        events={{
          scroll: checkScrollPosition
        }}
      >
        <Container>{children}</Container>
      </OverlayScroll>
      <TopButton scrollRef={scrollRef} showTopButton={showTopButton} />
    </Wrapper>
  ) : (
    <>{children}</>
  )
}

const BoardListContainer = ({
  boardType,
  layoutType = '',
  options,
  serviceAlias = 'purple'
}) => {
  const history = useHistory()
  const location = useLocation()
  const { gameCode, boardAlias, ...rest } = useParams()
  const categoryAlias = `${boardAlias}_${gameCode}`
  const didRefetch = useRef(false)

  // 쿼리 - 리스트 조회
  const {
    handleMoreList,
    listData,
    query,
    isLoading,
    isError: isListError,
    isSuccess: isListSuccess,
    isFetching,
    refetch: articleRefetch
  } = useGetBoardList({
    boardAlias,
    categoryAlias,
    serviceAlias
  })

  // 쿼리 - 공지 조회
  const {
    topNoticeData,
    isError: isNoticeError,
    isSuccess: isNoticeSuccess,
    refetch: noticeRefetch
  } = useGetTopNoticeList({
    boardAlias,
    categoryAlias,
    skip: options?.notice === false ? true : false
  })

  const isTotalListSuccess = useMemo(
    () => isListSuccess && isNoticeSuccess,
    [isListSuccess, isNoticeSuccess]
  )

  const isTotalListError = useMemo(
    () => isListError && isNoticeError,
    [isListError, isNoticeError]
  )

  // 이펙트 - 쿼리 호출 이펙트
  useEffect(() => {
    if (location.state?.refetch && !didRefetch.current) {
      articleRefetch()
      noticeRefetch()
      didRefetch.current = true
      history.replace({
        ...location,
        state: undefined
      })
    }
    return () => {
      didRefetch.current = false
    }
  }, [location, articleRefetch, noticeRefetch, history])

  return (
    <BoardListWrapper hasScroll={layoutType === 'popup'}>
      <ListHeader>
        <SearchForm
          key={categoryAlias}
          boardType={boardType}
          gameCode={gameCode}
          boardAlias={boardAlias}
          serviceAlias={serviceAlias}
          options={options}
          align={layoutType === 'launcher' ? 'right' : ''}
          width={layoutType === 'launcher' ? '596px' : '100%'}
        />
      </ListHeader>
      <BoardList
        handleMoreList={handleMoreList}
        data={listData}
        searchData={query}
        boardType={boardType}
        topData={topNoticeData}
        isLoading={isLoading}
        isFetching={isFetching}
        isTotalListSuccess={isTotalListSuccess}
        isTotalListError={isTotalListError}
        options={options}
      />
    </BoardListWrapper>
  )
}

export default BoardListContainer
