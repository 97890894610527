// 게임목록 카테고리 타입
export const GAME_CATEGORY_TYPE = {
  PC: 'pc',
  MOBILE: 'mobile'
}

// 게임목록 서브링크 타입
export const GAME_SUB_LINK_TYPE = {
  NSHOP: 'nshop',
  APPLE: 'apple',
  GOOGLE: 'google',
  ONE_STORE: 'onestore',
  NEWS: 'news' // SIE 게임 소식 게시판
}

// 콘티 국가 그룹(KEY: 각 그룹별 대표국가)
export const CONTI_COUNTRY_GROUP = {
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: [
    'US', //미국
    'AA', //Unknown
    'CA', //캐나다
    'DE', // 독일
    'GB', //영국
    'FR', //프랑스
    'IT', //이탈리아
    'ES', //스페인
    'SE', //스웨덴
    'IE', //아일랜드
    'PT', //포르투칼
    'FI', //핀란드
    'DK', //덴마크
    'CH', //스위스
    'BR', //브라질
    'CL', //칠레
    'AR', //아르헨티나
    'PE', //페루
    'MX', //멕시코
    'NO', //노르웨이
    'CO', //콜롬비아
    'EC', //에콰도르
    'AT', //오스트리아
    'NZ', //뉴질랜드
    'CZ', //체코
    'BE', //벨기에
    'NL', //네덜란드
    'TR', //터키(튀르키예)
    'RO', //루마니아
    'GR', //그리스
    'LT', //리투아니아
    'AU' //호주
  ],
  KR: [
    'KR', //한국
    'ID', //인도네시아
    'TH', //태국
    'SA', //사우디아라비아
    'AE', //아랍에미리트
    'QA', //카타르
    'RU', //러시아
    'UA', //우크라이나
    'PL', //폴란드
    'BY', //벨라루스
    'IL', //이스라엘
    'KW', //쿠웨이트
    'KZ', //카자흐스탄
    'UZ', // 우즈베키스탄
    'AZ', //아제르바이잔
    'GE', //조지아
    'MD', //몰도바
    'AM', //아르메니아
    'MY', //말레이시아
    'SG', //싱가포르
    'PH' //필리핀
  ]
}

// 게임 플랫폼
export const GAME_PLATFORM_TYPE = {
  PC: 'pc',
  MOBILE: 'mobile',
  SIE: 'SIE'
}

// 상품 타입
export const PRODUCT_TYPE = {
  EDITION: 'EDITION',
  DLC: 'DLC',
  BUNDLE: 'BUNDLE' // 묶음상품
}

// 게임 상세 타입
export const GAME_VIEW_TYPE = {
  BASIC: 'BASIC',
  MAIN: 'MAIN'
}

// shop에 전달하는 퍼플 앱그룹코드
export const PURPLE_APP_GROUP_CODE = 'pps'

// 게임상세 메뉴 타입
export const GAME_MENU_TYPE = {
  ACHIEVEMENT: 'achievement',
  REVIEW: 'review',
  BOARD: 'board'
}
