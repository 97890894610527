import 'setupPublicPath'
import reportWebVitals from 'reportWebVitals'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import { Theme } from '@purple/design/react'
import Cookies from 'js-cookie'

import 'language/i18n'

import store from 'config/storeConfig'
import history from 'config/historyConfig'
import 'config/analyticsConfig'

// GTM
import GoogleAnalytics from 'config/analyticsConfig'

import { NormalizeCSS } from 'assets/styles/normalize'
import { CommonCSS } from 'assets/styles/common'
import { SwiperCSS } from 'assets/styles/swiper'
import 'overlayscrollbars/overlayscrollbars.css'
import 'assets/styles/overlayScroll.css'

import { initErrorReport } from '@purple/common/utils/errorReportUtility'
import { Config as config } from '@purple/common/utils/globalUtility'
import { getBaseUrl } from '@purple/common/utils/commonUtility'
import { appendFontCss } from 'utils/storeServiceUtility'

// Constants
import { ACCOUNT_LOGIN_TYPE } from '@purple/common/constants/accountTypes'

import App from 'App'

import 'native/nativeAsync'
import { npQuery } from 'store/services/query/npQuery'

if (process.env.NODE_ENV === 'production') {
  const user =
    window.NGP_VARS && window.NGP_VARS.user ? window.NGP_VARS.user : ''

  GoogleAnalytics.initGTM({
    login_type:
      window.NGP_VARS?.user?.userCredentialCode ?? ACCOUNT_LOGIN_TYPE.GUEST,
    login_status: window.NGP_VARS?.user?.npLoggedIn ?? false,
    user_user_agent: window.navigator.userAgent ?? '',
    previous_visited_game_site: Cookies.get('visitedGame') ?? ''
  })

  initErrorReport({
    sentryUrl: config?.sentryUrl,
    envName: config?.envName,
    user,
    tagList: [
      {
        key: 'buildNumber',
        value: config['build.buildNumber']
      },
      {
        key: 'buildVersion',
        value: config['build.version']
      },
      {
        key: 'envName',
        value: config?.envName
      }
    ]
  })
} else {
  // 개발모드 로그인 체크
  store.dispatch(
    npQuery.endpoints.checkLoginUser.initiate({
      subscribe: false,
      forceRefetch: true
    })
  )
}

sessionStorage.removeItem('errorCount')
appendFontCss()

ReactDOM.createRoot(document.getElementById('root')).render(
  <HelmetProvider>
    <ThemeProvider theme={Theme(config.launcherTheme || 'dark')}>
      <Router basename={getBaseUrl()} history={history}>
        <Provider store={store}>
          <App />
          <NormalizeCSS />
          <CommonCSS />
          <SwiperCSS />
          {/* <ContentLoading /> */}
        </Provider>
      </Router>
    </ThemeProvider>
  </HelmetProvider>
)

/* register */
// serviceWorker.register()

/* unregister */
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.getRegistrations().then(function(registrations) {
//     for (const registration of registrations) {
//       registration.unregister()
//     }
//   })
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
