// Utils
import {
  Config as config,
  configGameCodeGroup,
  configGameCode,
  configGameOrderList
} from '@purple/common/utils/globalUtility'
import { CountryCode } from '@purple/common/utils/locationUtility'
import {
  initGameCodeList,
  countryGameCodeList
} from '@purple/common/utils/gameUtility'

// ---- 퍼플톡 chatUtility에서 가져옴

// 런칭 캐릭터 채팅생성제한 여부 확인
export const isChatLimited = (gameCode) => {
  const chatLimited = (config && config.chatLimited) || ''
  const configCodeList = chatLimited
    .split(',')
    .map((gameCode) => gameCode.trim())
  return configCodeList.indexOf(gameCode) !== -1
}

// 사전캐릭터 채팅생성제한 여부 확인
export const isPreChatLimited = (gameCode) => {
  const chatLimited = (config && config.preChatLimited) || ''
  const configCodeList = chatLimited
    .split(',')
    .map((gameCode) => gameCode.trim())
  return configCodeList.indexOf(gameCode) !== -1
}

///////

// plaync 까지 포함하여 게임순서 연동된 gameCodeList
export const configGameCodeOrderList = ({
  statusList = ['launchChat', 'preChat'],
  type
} = {}) => {
  let gameCodeList = statusList.reduce(
    (acc, status) => [...new Set(acc.concat(...countryGameCodeList(status)))],
    []
  )

  // 게임 순서 정렬
  // TODO: NCJ/K 앱스토어 로그인 계정 필터링 확인 필요
  const gameOrderList = initGameCodeList()
  // const npUserStoreType = store?.getState().userSlice.npUserStoreType || ''

  if (!!gameOrderList.length && !!gameCodeList.length) {
    gameCodeList = gameOrderList.reduce((acc, curr) => {
      if (gameCodeList.includes(curr)) {
        acc.push(curr)
      }
      return acc
    }, [])
  }

  return gameCodeList
}

/**
 *  채팅 이용 가능한 gameCodeList
 */
export const chatGameCodeList = ({ type } = {}) => {
  const gameCodeList = configGameCodeOrderList({ type }).filter(
    (gameCode, index, array) =>
      array.indexOf(gameCode) === index &&
      !isChatLimited(gameCode) &&
      !isPreChatLimited(gameCode)
  )

  return [...gameCodeList, configGameCode('nc')]
}
