// Constants
import { STORAGE_KEY } from 'constants/storageType'

export const defaultData = {
  [STORAGE_KEY.PURPLE_INFORMATION]: {
    state: null
  },
  [STORAGE_KEY.VIDEO_PLAYER_INFO]: {
    isMuted: false,
    volume: 100
  },
  [STORAGE_KEY.LOBBY_VOD_WATCHED]: {},
  [STORAGE_KEY.GAME_LIST_FILTER_VIEW]: false,
  [STORAGE_KEY.STORE_REVIEW_RECOMMEND]: {},
  [STORAGE_KEY.OPTIN_NO_DISPLAY]: {}
}
