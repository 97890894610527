import { createSlice } from '@reduxjs/toolkit'
import { dayjs } from '@purple/common/utils/dayjsUtility'

// initial state
export const initialState = {
  pageProcessStatus: false,
  currentTimeInfo: {
    currentTime: null,
    diff: null
  },
  isFocusStatus: true,
  prevPathname: undefined,
  fromLogName: undefined // 클릭한 배너의 roomName
}

// reducer Slice
const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  // 생성한 액션 (동기 처리 리듀서)
  reducers: {
    setPageProcessStatus: (state, action) => {
      state.pageProcessStatus = action.payload
    },
    setCurrentTimeInfo: (state, action) => {
      const { currentTime } = action.payload
      const diff = dayjs(currentTime).diff(dayjs()) // milliseconds
      state.currentTimeInfo = {
        currentTime: currentTime,
        diff: diff
      }
    },
    setNativeFocusStatus: (state, action) => {
      state.isFocusStatus = action.payload
    },
    setPrevPathname: (state, action) => {
      state.prevPathname = action.payload
    },
    setFromLogName: (state, action) => {
      state.fromLogName = action.payload
    }
  }
})

const { actions, reducer } = appSlice

// Actions 내보내기
export const appActions = actions
// Selector 내보내기
export const appSelector = (state) => state.appSlice
// Reducer 내보내기
export default reducer
