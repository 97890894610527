import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { Config as config } from '@purple/common/utils/globalUtility'
import { isStorePage } from 'utils/storeServiceUtility'
import { setDayjsLocale } from '@purple/common/utils/dayjsUtility'

import resources from 'excel-to-json/source/index'

const order = isStorePage()
  ? ['querystring', 'cookie', 'navigator']
  : config.envName === 'LOCAL'
  ? ['querystring', 'htmlTag', 'localStorage']
  : ['navigator', 'localStorage']

i18n
  .use(detector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        order: order,
        lookupLocalStorage: 'i18nextLng',
        lookupQuerystring: 'locale',
        lookupCookie: 'locale',
        htmlTag: document.documentElement,
        checkWhitelist: true
      },
      resources: resources,
      //lng: 'ko',

      fallbackLng: 'en-US',
      debug: true,
      keySeparator: false,
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong']
    },
    () => {
      setDayjsLocale(i18n.language)
    }
  )
export default i18n
