import { createSlice } from '@reduxjs/toolkit'
import { npQuery } from 'store/services/query/npQuery'
import { getTheme } from '@purple/common/utils/commonUtility'
import { isMobile, isPurpleApp } from '@purple/common/utils/checkUtility'

// Constants
import { GAME_CATEGORY_TYPE } from 'constants/storeTypes'

// initial state
export const initialState = {
  storeTestValue: 'success',
  storeTheme: getTheme() || 'dark',
  activeGameTab:
    isMobile || isPurpleApp ? GAME_CATEGORY_TYPE.MOBILE : GAME_CATEGORY_TYPE.PC,
  launcherClosedPopupName: null,
  loginStatus: window.NGP_VARS?.user?.id ? true : false,
  npUserId: window.NGP_VARS?.user?.id,
  npUserName: window.NGP_VARS?.user?.name,
  npUserCountryCode: window.NGP_VARS?.user?.countryCode
}

// reducer Slice
const storeSlice = createSlice({
  name: 'storeSlice',
  initialState,
  // 생성한 액션 (동기 처리 리듀서)
  reducers: {
    setTheme: (state, action) => {
      state.storeTheme = action.payload
    },
    setActiveGameTab: (state, action) => {
      state.activeGameTab = action.payload
    },
    setGtmInit: (state, action) => {
      state.gtmInit = action.payload
    },
    setLauncherClosedPopupName: (state, action) => {
      state.launcherClosedPopupName = action.payload
    },
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload
    }
  },
  // 생선한 액션 외 외부 액션 참조 (비동기 처리 리듀서)
  extraReducers: (builder) => {
    builder.addMatcher(
      npQuery.endpoints.checkLoginUser.matchFulfilled,
      (state, action) => {
        const { user_id, user_name, country_code } = action.payload
        state.loginStatus = true
        state.npUserId = user_id ?? window.NGP_VARS?.user?.id
        state.npUserName = user_name ?? window.NGP_VARS?.user?.name
        state.npUserCountryCode =
          country_code ?? window.NGP_VARS?.user?.countryCode
      }
    )
  }
})

const { actions, reducer } = storeSlice

// Actions 내보내기
export const storeActions = actions
// Selector 내보내기
export const storeSelector = (state) => state.appSlice
// Reducer 내보내기
export default reducer
