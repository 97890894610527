import { purpleApi } from 'store/services/purpleApi'

// utils
import { commonSessionError } from 'utils/appUtility'

export const gameQuery = purpleApi.injectEndpoints({
  endpoints: (build) => ({
    getGameList: build.query({
      query: (params) => ({
        url: '/api/store/games',
        method: 'GET',
        params
      })
    }),
    // 게임 상세 조회
    getGameDetail: build.query({
      query: ({ gameCode, ...params }) => ({
        url: `/api/store/games/${gameCode}`,
        method: 'GET',
        params
      })
    }),
    // 게임 등록 상품 조회
    getGameProducts: build.query({
      query: ({ gameCode, ...params }) => ({
        url: `/api/store/goods/games/${gameCode}`,
        method: 'GET',
        params
      })
    }),
    // 상품 상세 조회(패키지 정보 등..)
    getGoodsDetails: build.query({
      query: ({ goodsId, ...params }) => ({
        url: `/api/store/goods/${goodsId}/details`,
        method: 'GET',
        params
      })
    }),

    // 리뷰

    // 게임 리뷰 평균 점수 조회
    getGameReviewsAverage: build.query({
      query: ({ gameCode, ...params }) => ({
        url: `/api/store/games/${gameCode}/reviews/scores/average`,
        method: 'GET',
        params
      })
    }),
    // 게임 리뷰 목록 조회 (페이징)
    getGameReviews: build.query({
      query: ({ gameCode, ...params }) => ({
        url: `/api/store/games/${gameCode}/reviews`,
        method: 'GET',
        params
      }),
      keepUnusedDataFor: 0,
      serializeQueryArgs: ({ queryArgs: { gameCode, packageBundleId } }) => ({
        gameCode,
        packageBundleId
      }),
      merge: (currentCache, newItems) => {
        currentCache.reviews.push(...newItems.reviews)
        currentCache.has_more = newItems.has_more
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !previousArg ||
          (currentArg.previousValue &&
            currentArg.previousValue !== previousArg.previousValue)
        )
      }
    }),
    // 나의 게임 리뷰 조회
    getMyGameReviews: build.query({
      query: ({ gameCode, ...params }) => ({
        url: `/api/store/games/${gameCode}/reviews/me`,
        method: 'GET',
        params
      }),
      transformResponse: (response, meta, arg) => {
        const { reviews } = response
        if (reviews.length > 0) {
          return reviews[0]
        }
      }
    }),

    // 게임 리뷰 등록
    postGameReview: build.mutation({
      query: ({ gameCode, content, score, ...params }) => ({
        url: `/api/store/games/${gameCode}/reviews`,
        method: 'POST',
        params: params,
        body: {
          content,
          score
        }
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // 게임 리뷰 목록 갱신
          dispatch(
            gameQuery.util.updateQueryData(
              'getGameReviews',
              {
                gameCode: params.gameCode,
                productType: params.productType,
                packageBundleId: params.packageBundleId
              },
              (draft) => {
                draft.reviews.unshift(data)
              }
            )
          )
          // 평점 조회
          dispatch(
            gameQuery.endpoints.getGameReviewsAverage.initiate(
              {
                gameCode: params.gameCode,
                productType: params.productType,
                packageBundleId: params.packageBundleId
              },
              { forceRefetch: true }
            )
          )
          // 내 게임 리뷰 갱신
          dispatch(
            gameQuery.endpoints.getMyGameReviews.initiate(
              {
                gameCode: params.gameCode,
                productType: params.productType,
                packageBundleId: params.packageBundleId
              },
              { forceRefetch: true }
            )
          )
        } catch (response) {
          if (response?.error?.status === 401) {
            commonSessionError()
          }
        }
      }
    }),

    // 리뷰 수정
    editGameReview: build.mutation({
      query: ({ gameCode, reviewId, content, score }) => ({
        url: `/api/store/games/${gameCode}/reviews/${reviewId}`,
        method: 'PATCH',
        body: {
          content,
          score
        }
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          // 내 게임 리뷰 갱신
          dispatch(
            gameQuery.util.updateQueryData(
              'getMyGameReviews',
              {
                gameCode: params.gameCode,
                productType: params.productType,
                packageBundleId: params.packageBundleId
              },
              (draft) => {
                draft.content = data.content
                draft.score = data.score
                draft.updated_date_time = data.updated_date_time
              }
            )
          )
          // 게임 리뷰 목록 갱신
          dispatch(
            gameQuery.util.updateQueryData(
              'getGameReviews',
              {
                gameCode: params.gameCode,
                productType: params.productType,
                packageBundleId: params.packageBundleId
              },
              (draft) => {
                const targetIdx = draft?.reviews.findIndex((item) => {
                  return item.id === params.reviewId
                })
                if (targetIdx > -1) {
                  draft.reviews[targetIdx] = data
                }
              }
            )
          )

          // 평점 조회
          dispatch(
            gameQuery.endpoints.getGameReviewsAverage.initiate(
              {
                gameCode: params.gameCode,
                productType: params.productType,
                packageBundleId: params.packageBundleId
              },
              { forceRefetch: true }
            )
          )
        } catch (response) {
          if (response?.error?.status === 401) {
            commonSessionError()
          }
        }
      }
    })
  })
})

export const {
  useGetGameListQuery,
  useGetGameDetailQuery,
  useGetGameProductsQuery,
  useGetGoodsDetailsQuery,

  // 게임 리뷰
  useGetGameReviewsAverageQuery,
  useGetGameReviewsQuery,
  useGetMyGameReviewsQuery,
  usePostGameReviewMutation,
  useEditGameReviewMutation
} = gameQuery
