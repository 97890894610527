import i18n from 'i18next'
import { matchPath } from 'react-router-dom'

// RTK
import { npQuery } from 'store/services/query/npQuery'

// Utils
import store from 'config/storeConfig'
import history from 'config/historyConfig'
import { Config as config, setConfig } from '@purple/common/utils/globalUtility'
import { splitPaths, onReload } from '@purple/common/utils/commonUtility'
import { isLauncher, isPurpleApp } from '@purple/common/utils/checkUtility'
import { onNativeReload } from 'native/nativeUtility'
import { mobileScheme } from '@purple/common/utils/mobileUtility'

// Components
import { confirmPopup } from 'components/Popup'

class SessionCheck {
  constructor() {
    this.queryRef = null
    this.pollingInterval = (config['session.minute'] || '5') * 60 * 1000
    this.initialTimer = null
  }

  clearTimer = () => {
    if (this.initialTimer) {
      clearTimeout(this.initialTimer)
      this.initialTimer = null
    }
  }

  startInterval = () => {
    this.clearTimer()
    this.initialTimer = setTimeout(() => {
      const queryRef = store.dispatch(
        npQuery.endpoints.sessionCheck.initiate('polling', {
          subscriptionOptions: {
            pollingInterval: this.pollingInterval
          }
        })
      )
      this.queryRef = queryRef
    }, this.pollingInterval)
  }

  updateInterval = (updatedInterval) => {
    // console.log(
    //   `설정값 변경으로 기존 구독 취소하고 인터벌 ${
    //     updatedInterval / 60000
    //   }분 으로 재설정`
    // )
    this.pollingInterval = updatedInterval
    this.queryRef.unsubscribe()
    this.startInterval()
  }

  sessionRecovery = () => {
    if (isLauncher) {
      onNativeReload()
    } else if (isPurpleApp) {
      mobileScheme.webviewSSO()
    }
  }

  sessionSuccess = (data) => {
    // console.log(`${new Date().getMinutes()}분에 세션 체크 api 성공`)

    const resConfig = data.config || {}
    const resUser = data.user || {}

    // 사용자 선택 새로고침
    if (
      'pageVersion' in config &&
      'pageVersion' in resConfig &&
      config.pageVersion !== resConfig.pageVersion
    ) {
      const match = matchPath(history.location.pathname, {
        path: splitPaths(resConfig['pagePaths'])
      })
      if (match && match.path !== '') {
        confirmPopup(
          {
            msg: i18n.t(
              'refresh the page to apply the newly updated information'
            ),
            type: 'confirm',
            onRight: () => {
              onReload()
            }
          },
          { stringKey: 'refresh' }
        )
      }
    }

    // 사용자 강제 새로고침
    if (
      'refreshVersion' in config &&
      'refreshVersion' in resConfig &&
      config.refreshVersion !== resConfig.refreshVersion
    ) {
      const match = matchPath(history.location.pathname, {
        path: splitPaths(resConfig['refeshPaths'])
      })
      if (match && match.path !== '') {
        confirmPopup(
          {
            msg: i18n.t(
              'refresh the page to apply the newly updated information'
            ),
            type: 'alert',
            showCloseIcon: false,
            onRight: () => {
              onReload()
            }
          },
          { stringKey: 'refresh' }
        )
      }
    }

    // 개발자 강제 새로고침
    if (
      'reloadVersion' in config &&
      'reloadVersion' in resConfig &&
      config.reloadVersion !== resConfig.reloadVersion
    ) {
      const match = matchPath(history.location.pathname, {
        path: splitPaths(resConfig['reloadPaths'])
      })
      if (match && match.path !== '') {
        console.log('execute : page been force reloaded')
        onReload()
      }
    }

    // 설정 업데이트
    if ('config' in data) {
      setConfig({
        ...resConfig,
        pageVersion: window.CONFIG.pageVersion
      })

      // 세션 체크 설정값 변경 시 쿼리 재구독
      const currentInterval = this.pollingInterval
      const updatedInterval = resConfig['session.minute'] * 60 * 1000
      if (updatedInterval && currentInterval !== updatedInterval) {
        this.updateInterval(updatedInterval)
      }
    }

    // NGP_VARS 업데이트
    if ('NGP_VARS' in window) {
      window.NGP_VARS = {
        ...window.NGP_VARS,
        user: resUser
      }
    }
  }
}

export default new SessionCheck()
